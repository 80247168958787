























































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { Store } from 'vuex';
import {
    IUserProfile,
    IProductUpdate,
    IProductImageUpdate,
    IBrandUpdate
} from '@/interfaces';
import {
    readProducts,
    readAffiBrands,
    readImpersonated,
} from '@/store/affi/getters';
import {
    commitSetProducts,
} from '@/store/affi/mutations';
import {
    dispatchGetProducts,
    dispatchGetBrands,
    dispatchUpdateBrand,
    dispatchUpdateProductBulk,
    dispatchUpdateProductImage
} from '@/store/affi/actions';
import { api } from '@/api';

import { ttt } from '@/nutils';

interface IPagination {
  rowsPerPage: number;
  totalItems: number;
  page: number;
};

type ProductImages = {
  id: number;
  image: string;
  srcs: string[];
};

@Component
export default class Products extends Vue {
  public ttt = ttt;
  public pagination: IPagination = { rowsPerPage: 10, totalItems: 0, page: 1, };
  public dirty: number[] = [];
  public search: string = '';

  public editImageDialog: boolean = false;
  public selected: ProductImages[] = [];

  public headers = [
    { text: ttt('Move'), sortable: false, },
    { text: ttt('ID'), sortable: false, value: 'shopify_prod_id', align: 'left', },
    { text: ttt('Name'), sortable: false, value: 'product_name', align: 'left', },
    { text: ttt('Image'), sortable: false, align: 'left', },
    { text: '', sortable: false, align: 'left', },
    { text: ttt('Date Added'), sortable: false, value: 'date_added', align: 'left', },
    { text: ttt('Price'), sortable: false, value: 'price', align: 'center' },
    { text: ttt('Link'), sortable: false, value: 'product_url', align: 'left', },
    { text: ttt('Status'), sortable: false, value: 'is_data_complete', align: 'left', },
    { text: ttt('In Widget'), sortable: false, value: 'is_postpurchase', align: 'center', },
  ];

  public myCroppa : any = {};
  public initialImage : HTMLImageElement = new Image();
  // public initialImageSrc: string = 'https://zhanziyang.github.io/vue-croppa/static/500.jpeg';
  public sliderMin : number = 0;
  public sliderMax : number = 0;
  public sliderVal : number = 0;
  public canvasSize : number = 350 * 2;
  public imageSaving : boolean = false;
  public dataUrl : string = '';
  public carouselFirst : number = 0;


  get pages() {
    if (this.pagination.totalItems === 0)
        this.pagination.totalItems = this.products.length;
    return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage);
  }

  get products() {
    return readProducts(this.$store);
  }

  get brand() {
    const imp = readImpersonated(this.$store);
    const bs = readAffiBrands(this.$store)
        .filter( (obj) => ( imp === -1 || obj && obj.owner_id === imp ) );
    return bs[0];
  }

  public async mounted() {
    const imp = readImpersonated(this.$store);

    await dispatchGetProducts(this.$store, imp);
    await dispatchGetBrands(this.$store);

    this.dirty = [];
  }

  public async submit() {
    // console.log('updating:');
    const updList: { id: number, product: IProductUpdate }[] = [];
    let i=0;
    for (const e of this.products) {
      if (e.position_order !== i || this.dirty.includes(e.id)) {
        const updatedProduct: IProductUpdate = {
          is_postpurchase: e.is_postpurchase,
          position_order: i,
          ...(e.is_user_pname && {
              is_user_pname: e.is_user_pname,
              product_name: e.product_name
          }),
        };
        updList.push( { id: e.id, product: updatedProduct } );
        // console.dir(updatedProduct);
      }
      i+=1;
    };
    // update status of brand
    const updBrand: IBrandUpdate = { has_choose_products: true };

    await Promise.all([
        dispatchUpdateBrand(this.$store, { id: this.brand!.id, brand: updBrand }),
        dispatchUpdateProductBulk(this.$store, updList)
    ]);

    this.$router.push('/main/dashboard');
  }

  public cancel() {
    this.$router.back();
  }

  public openwindow(url) {
    window.open(url, '_blank');
  }
  public movetop(item) {
    const idx = this.products.findIndex((e) => e.id === item.id);
    if (idx <= 0) return;
    this.arraymove(this.products, idx, 0);
  }
  public moveup(item) {
    const idx = this.products.findIndex((e) => e.id === item.id);
    if (idx <= 0) return;
    this.arraymove(this.products, idx, idx-1);
  }
  public movedown(item) {
    const idx = this.products.findIndex((e) => e.id === item.id);
    if (idx >= (this.products.length-1)) return;
    this.arraymove(this.products, idx, idx+1);
  }
  public arraymove(arr, fromIndex, toIndex) {
    const element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  }

  public orderby_date_added() {
    this.products.sort((e1,e2) => {
      if (e1.date_added > e2.date_added) { return 1; }
      if (e1.date_added < e2.date_added) { return -1; }
      return 0;
    });
  }
  public orderby_position_order() {
    this.products.sort((e1,e2) => e1.position_order - e2.position_order);
  }
  public toggled(item) {
    // console.log(item);
    this.dirty.push(item.id);
  }

  public selectedImageChange(event) {
    // console.log('on change image');

    if (this.selected.length > 0) {
      this.setInitialImage(this.selected[0].srcs[event]);
    }
  }

  setInitialImage(source: string) {
    // console.log('before crossorigin');
    const image = new Image();
    image.setAttribute('crossorigin', 'anonymous');
    image.src = source;
    // console.log(source);
    this.initialImage = image;
    if (this.myCroppa.refresh !== undefined) {
        this.myCroppa.refresh();
        // console.log('after myCroppa.refresh');
    }
  }

  public editImage(item) {
    // console.log(`urls ${item.image}`);
    this.selected = [ {id: item.id, image: item.image,
        srcs: JSON.parse(item.all_images_urls) } ];
    this.carouselFirst = 0;

    // if the image is not in the shopify list insert it
    if (this.selected[0].srcs.indexOf(item.image) < 0) {
        this.selected[0].srcs.splice(0, 0, item.image );
    }

    this.setInitialImage(item.image);
    // console.log(this.selected);

    this.editImageDialog = true;
  }

  /* CROPPA INIT */
  onSliderChange(evt) {
    // console.log('on slider change');
    const increment = evt.target.value;
    this.myCroppa.scaleRatio = +increment;
  }
  onZoom(evt) {
    // console.log('on zoom');
    this.sliderVal = this.myCroppa.scaleRatio;
  }
  onNewImageDrawn(evt) {
    // console.log('on newimagedrawn');
    this.sliderVal = this.myCroppa.scaleRatio;
    this.sliderMin = this.myCroppa.scaleRatio / 5;
    this.sliderMax = this.myCroppa.scaleRatio * 2;
  }
  onDraw(ctx) {
    if (!this.imageSaving) {
      ctx.save()
      ctx.globalAlpha = 0.8
      // H
      ctx.beginPath()
      ctx.lineWidth = 1;
      ctx.strokeStyle = '#000000'
      ctx.moveTo(0, this.canvasSize / 3)
      ctx.lineTo(this.canvasSize, this.canvasSize / 3)
      ctx.stroke();
      ctx.beginPath()
      ctx.lineWidth = 1;
      ctx.strokeStyle = '#000000'
      ctx.moveTo(0, this.canvasSize / 1.5)
      ctx.lineTo(this.canvasSize, this.canvasSize / 1.5)
      ctx.stroke();
      // V
      ctx.beginPath()
      ctx.lineWidth = 1;
      ctx.strokeStyle = '#000000'
      ctx.moveTo(this.canvasSize / 3, 0)
      ctx.lineTo(this.canvasSize / 3, this.canvasSize)
      ctx.stroke()
      ctx.beginPath()
      ctx.lineWidth = 1;
      ctx.strokeStyle = '#000000'
      ctx.moveTo(this.canvasSize / 1.5, 0)
      ctx.lineTo(this.canvasSize / 1.5, this.canvasSize)
      ctx.stroke()
      // circle outline
      // ctx.beginPath()
      // ctx.globalAlpha = 1.0
      // ctx.strokeStyle = '#ffffff';
      // ctx.lineWidth = 10;
      // ctx.arc(this.canvasSize / 2, this.canvasSize / 2, this.canvasSize / 2, 0, 2 * Math.PI, true)
      // ctx.stroke()
      // ctx.restore()
      // inverse circle fill
      // ctx.save()
      // ctx.fillStyle = 'rgba(0, 0, 0, 0.5)';
      // ctx.beginPath()
      // ctx.arc(this.canvasSize / 2, this.canvasSize / 2, this.canvasSize / 2, 0, 2 * Math.PI)
      // ctx.rect(this.canvasSize, 0, 0 - this.canvasSize, this.canvasSize)
      // ctx.fill()
      // ctx.closePath()
    } else {
      this.myCroppa.generateBlob( async blob => await this.saveImageToProduct( blob ),
          'image/png', 0.9 // 90% compressed png file
      )
      this.imageSaving = false
      this.onDraw(this.myCroppa.getContext())           // redraws the component with the overlay
    }
  }

  saveImage() {
    this.imageSaving = true;
    const croppaImageMetaData = this.myCroppa.getMetadata();
    const ctx = this.myCroppa.getContext();
    ctx.clearRect(0, 0, this.canvasSize, this.canvasSize);
    ctx.drawImage(this.myCroppa.img, 0, 0, this.canvasSize, this.canvasSize);
    this.myCroppa.applyMetadata(croppaImageMetaData);
    // const blob = await this.myCroppa.promisedBlob('image/png');
  }
  /* CROPPA ENDS */

  async saveImageToProduct(blob) {
    const token = this.$store.state.main.token;
    const resp = await api.uploadFile(token, 'x.png', blob);
    // console.dir(resp);
    if (resp.status === 200) {
      // save Product
      const updatedProduct: IProductImageUpdate = {
        image: resp.data.src,
      };
      // console.log(`new image in: ${updatedProduct.image}`);
      await dispatchUpdateProductImage(this.$store, { id: this.selected[0].id, product: updatedProduct });
      // update delete and append again the product, needs to reorder to the  old position order.
      this.orderby_position_order();
      this.editImageDialog = false;
    } else {
      // notify error
    }
  }

  savePname(item) {
      // console.log('cambio de nombre');
      // console.dir(item);
      item.is_user_pname = true;
      this.dirty.push(item.id);
  }

}
